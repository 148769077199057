import {
  Avatar,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Flex,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { IoMdMenu } from 'react-icons/io';
import { CiSearch } from 'react-icons/ci';
import SideDrawer from './SideDrawer';
import { useGetProfile } from '../../../service/query/auth';

const Header = () => {
  const [isMobile] = useMediaQuery('(max-width: 999px)');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data } = useGetProfile();

  return (
    <Flex
      flexDirection="column"
      bg="#fff"
      pos="fixed"
      w="100%"
      zIndex="5"
      pt={isMobile ? '30px' : '10px'}
      pb={isMobile ? '30px' : '12px'}
    >
      <Flex
        justifyContent="space-between"
        align="center"
        pl={isMobile ? '20px' : '320px'}
        pr={isMobile ? '20px' : '48px'}
        w="full"
      >
        {isMobile && (
          <Flex justifyContent="space-between" align="center" w="full">
            <Flex
              color="#BDBDBD"
              borderRadius="20px"
              border="1px solid rgba(104, 132, 202, 0.5)"
              p="7px"
              onClick={onOpen}
              w="fit-content"
              ml={isMobile ? '25px' : '320px'}
              cursor="pointer"
            >
              <IoMdMenu size="20px" />
            </Flex>
          </Flex>
        )}

        {!isMobile && (
          <Flex justifyContent="space-between" width="full">
            <Flex justifyContent="space-between">
              <Flex>
                <InputGroup>
                  <InputLeftElement h="50px">
                    <CiSearch />
                  </InputLeftElement>
                  <Input
                    flex={2}
                    w="full"
                    bg="#F9FAFB"
                    borderRadius="6px"
                    h="50px"
                    placeholder="Search here..."
                    _placeholder={{ fontSize: '13px', color: '#545B6A' }}
                  />
                </InputGroup>
              </Flex>
            </Flex>

            <Flex
              align="center"
              flex={1}
              gap="11px"
              w="100%"
              justifyContent="flex-end"
            >
              <Box>
                {/* <Flex
                  border="1px solid rgba(104, 132, 202, 0.30)"
                  rounded="full"
                  align="center"
                  justifyContent="center"
                  w="40px"
                  h="40px"
                  onClick={setShow}
                >
                  <GoBellFill />
                </Flex> */}
              </Box>
              <Avatar
                sx={{
                  img: {
                    objectPosition: 'top center',
                  },
                }}
                src={data?.data?.image}
                w="40px"
                h="40px"
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <SideDrawer isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};
export default Header;
